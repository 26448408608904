import _ from 'lodash';
import QueryString from 'query-string';
import { useEffect } from 'react';

import history from '../history';

import { displayNameForSubdivision } from '@omsmastersystem/pavo-intl';

const ServiceTypeEnum = {
    SalesRep: '1',
    B2B: '2',
    PriceChecker: '3',
    PointOfSale: '4',
};

const WebsiteRestrictionEnum = {
    FullRestriction: 'full',
    RestrictProductDisplay: 'partial',
    NoRestriction: 'none',
    MainPageOnly: 'main_page_only',
};

const siteAllowsOrdering = (websiteConfiguration) => {
    return websiteConfiguration.allow_ordering;
};

const siteAccessPartiallyRestrictedDueToNoLogin = (websiteConfiguration, auth) => {
    const { website_restriction } = websiteConfiguration;

    const isNotLoggedIn = auth === false;

    if (!siteAllowsOrdering(websiteConfiguration) && isNotLoggedIn) {
        return true;
    }

    switch (website_restriction) {
        case WebsiteRestrictionEnum.FullRestriction: {
            if (isNotLoggedIn) {
                return true;
            }
            break;
        }
        case WebsiteRestrictionEnum.RestrictProductDisplay: {
            if (isNotLoggedIn) {
                return true;
            }
            break;
        }
        case WebsiteRestrictionEnum.MainPageOnly: {
            if (isNotLoggedIn) {
                return true;
            }
            break;
        }
    }
    return false;
};

const siteAccessRestrictedDueToNoLogin = (websiteConfiguration, auth) => {
    const { website_restriction } = websiteConfiguration;

    const isNotLoggedIn = auth === false;

    if (!siteAllowsOrdering(websiteConfiguration) && isNotLoggedIn) {
        return true;
    }
    if (website_restriction === WebsiteRestrictionEnum.FullRestriction) {
        if (auth === false) {
            return true;
        }
    }
    if (website_restriction === WebsiteRestrictionEnum.MainPageOnly) {
        if (auth === false) {
            return true;
        }
    }
    return false;
};

// negative value for darken, positive value for lighten
// taken from https://css-tricks.com/snippets/javascript/lighten-darken-color/
const lightenDarkenColor = (col, amt) => {
    let usePound = false;

    if (col[0] === '#') {
        col = col.slice(1);
        usePound = true;
    }

    const num = parseInt(col, 16);

    let r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    let b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    let g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

const textColorForBackgroundColorHex = (backgroundColorHex) => {
    if (_.isEmpty(backgroundColorHex)) {
        return 'black';
    }
    let num = parseInt(backgroundColorHex.substr(1), 16);
    num >>>= 0;
    const b = num & 0xff,
        g = (num & 0xff00) >>> 8,
        r = (num & 0xff0000) >>> 16;

    // test for visibility: https://www.w3.org/TR/AERT/#color-contrast
    const brightness = Math.round(
        (parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000,
    );

    if (brightness > 140) {
        return 'black';
    } else {
        return 'white';
    }
};

const isLoggedIn = (auth) => {
    return auth != null && auth !== false && !_.isEmpty(auth);
};

const isPriceCheckerWebsite = (serviceType) => {
    return serviceType === ServiceTypeEnum.PriceChecker;
};

const isB2BWebsite = (serviceType) => {
    return serviceType === ServiceTypeEnum.B2B;
};

const isSalesRepWebsite = (serviceType) => {
    return serviceType === ServiceTypeEnum.SalesRep;
};

const isPointOfSaleWebsite = (serviceType) => {
    return serviceType === ServiceTypeEnum.PointOfSale;
};

const salesRepCustomerSelected = (serviceType, auth) => {
    return isSalesRepWebsite(serviceType) && !_.isEmpty(auth) && auth.customer_id;
};

const isCustomerSelected = (auth) => {
    return !_.isEmpty(auth?.customer_id);
};

const isSizeRunSystem = (websiteConfiguration) => {
    const { oms_system } = websiteConfiguration;

    return oms_system === 'APW' || oms_system === 'SHW';
};

const isAPW = (websiteConfiguration) => {
    const { oms_system } = websiteConfiguration;

    return oms_system === 'APW';
};

const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'auto' });
};

const isUsingUnits = (websiteConfiguration) => {
    return websiteConfiguration?.quantity_settings?.use_units === true;
};

const isMobile = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
);

function formatOrderStatus(item, isInvoice = false) {
    if (!isInvoice) {
        switch (item.order_status) {
            case 'cancelled':
                return 'Cancelled';
            case 'shipped':
                return 'Shipped';
            case 'partial_ship':
                return 'Partially Shipped';
            case 'void':
                return 'Void';
            case 'open':
            default:
                return 'Open';
        }
    } else {
        switch (item.status) {
            case 'void':
            case 'cancelled':
                return item.status.charAt(0).toUpperCase() + item.status.slice(1);
            default:
                return 'Shipped';
        }
    }
}

const productsURL = '/products';
const quickAddUrl = '/quick-add-products';

// Return URL links. Suffix of NID means you need to add '/:id' to the URL
const UrlEnum = {
    ACCOUNT_DASHBOARD: '/account/dashboard',
    ACCOUNT_PROFILE: '/account/profile',
    ACCOUNT_SETTINGS: '/account/settings',
    ABOUT_US: '/pages/about-us',
    CATEGORY: '/category',
    CONTACT: '/pages/contact',
    CHECKOUT: '/checkout',
    CUSTOMER_LIST: '/account/customer-list',
    CUSTOMER_DETAIL_NID: '/account/customer/detail',
    FAQ: '/pages/faq',
    HOME: '/',
    INVOICE_DETAIL_NID: '/account/invoices/detail',
    INVOICE_HISTORY: '/account/invoices',
    KEYBOARD_SHORTCUTS: '/pages/keyboard-shortcuts',
    LOGIN: '/pages/login',
    NOT_FOUND_404: '/pages/404',
    ORDER_DETAIL_NID: '/account/orders/detail',
    ORDER_HISTORY: '/account/orders',
    ORDER_SUCCESS: '/order-success',
    PAYMENT_METHODS: '/account/payment-methods',
    PAYMENT_HISTORY: '/account/payments',
    PRIVACY_POLICY: '/pages/privacy-policy',
    PRODUCTS: productsURL,
    PRODUCT_NID: '/product',
    QUICK_ADD_PRODUCTS: quickAddUrl,
    PRODUCT_CATEGORY_GETTER: (pathname, category) => {
        const path = pathname === quickAddUrl ? quickAddUrl : productsURL;
        return `${path}?category=${category}`;
    },
    CATEGORY_GETTER: (slug) => {
        return `/category/${slug}`;
    },
    REGISTER: '/pages/register',
    RESERVED_CART: '/account/reserved-cart',
    RESET_PASSWORD_NID: '/pages/reset-password',
    SHOPPING_CART: '/cart',
    TERMS_CONDITIONS: '/pages/terms-conditions',
    API_SCAN_ITEM_TO_CART: '/api/shopping_cart/scan',
};

function isProduction() {
    return process.env.REACT_APP_ENVIRONMENT === 'production';
}

function getQueryFilter() {
    const query = QueryString.parse(history.location.search);
    return query ?? {};
}

function isStateRequired(countryCode) {
    const countryHasOneSubdivision = displayNameForSubdivision(countryCode)?.length <= 1;
    return !countryHasOneSubdivision;
}
// Used for React Hooks. Runs fn only once.
const useMount = (fn) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fn, []);
};
// Navigates to either Products or Category details page if subcategories exist
function navigateToCategory(pathname, selectedCategory, categories) {
    const { category_id } = selectedCategory;
    const foundChild = categories.find((category) => category.parent_category_id === category_id);

    if (!foundChild) {
        history.push(UrlEnum.PRODUCT_CATEGORY_GETTER(pathname, selectedCategory.slug));
    } else {
        history.push(UrlEnum.CATEGORY_GETTER(selectedCategory.slug));
    }
}

const sanitizeNumber = (value) => value.replace(/[^\d.]/g, '');

const roundToFraction = (number, precision = 2) => {
    const expression = Math.pow(10, precision);
    return Number(Math.round(number * expression) / expression);
};

const PaymentMethodLabelForCode = {
    credit_card: 'Credit Card',
    check: 'Check',
    cash: 'Cash',
    cashier_check: `Cashier Check`,
    wire_transfer: 'Wire Transfer',
    ups_check: 'UPS Check',
    fedex_check: 'FedEx Check',
    refund: 'Refund',
    customer_credit: 'Customer Credit',
    credit_memo: 'Credit Memo',
    debit_memos: 'Debit Memo',
    bounced_check: 'Bounced Check',
    other: 'Other',
    returned: 'Returned',
};

const isUsingPayments = (websiteConfiguration) => {
    return websiteConfiguration.supports_payments && websiteConfiguration.show_payment_on_checkout;
};

const DashboardDateOptions = [
    {
        label: 'Last 7 days',
        value: '7_days',
    },
    {
        label: 'Last 30 days',
        value: '30_days',
    },
    {
        label: 'Last 12 months',
        value: '12_months',
    },
    {
        label: 'Month to Date',
        value: 'mtd',
    },
    {
        label: 'Quarter to Date',
        value: 'qtd',
    },
    {
        label: 'Year to Date',
        value: 'ytd',
    },
];

export {
    ServiceTypeEnum,
    WebsiteRestrictionEnum,
    siteAllowsOrdering,
    siteAccessPartiallyRestrictedDueToNoLogin,
    siteAccessRestrictedDueToNoLogin,
    lightenDarkenColor,
    textColorForBackgroundColorHex,
    isLoggedIn,
    isPriceCheckerWebsite,
    isB2BWebsite,
    isSalesRepWebsite,
    isPointOfSaleWebsite,
    salesRepCustomerSelected,
    isCustomerSelected,
    isSizeRunSystem,
    isAPW,
    scrollToTop,
    isUsingUnits,
    isMobile,
    formatOrderStatus,
    UrlEnum,
    isProduction,
    getQueryFilter,
    isStateRequired,
    useMount,
    navigateToCategory,
    sanitizeNumber,
    roundToFraction,
    PaymentMethodLabelForCode,
    isUsingPayments,
    DashboardDateOptions,
};
